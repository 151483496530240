@import "../../styles/tools";

$large-height: 92px;
$tablet-height: 92px;
$mobile-height: 62px;

.container {
    background: $color-off-white;
    height: $large-height;
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    transition: all 0.15s ease-in-out;
}

.container--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding-right: calc(50px + var(--common-scroll-width));
    z-index: 1006;

    background: rgba($color-off-white, 0.60);
    backdrop-filter: blur(12px);
}

.container--scrolled {
    box-shadow: 0px 4px 30px rgba(192, 192, 192, 0.3);
    height: $mobile-height;
}

.container-placeholder {
    background: $color-off-white;
    height: $large-height;
    width: 100vw;
}

.logo {
    display: flex;
    align-items: center;
    flex: 1;
}

.logo--normal {
    display: block;
}

.logo--small {
    display: none;
}

.main {
    flex: 3;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main--collapsed {
    justify-content: space-between;
}

.main__navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;

    .button--hidden {
        pointer-events: none !important;
        opacity: 0;
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    flex: 2;
}

.button, .button:link, .button:visited {
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: $color-title-active;
    padding: 15px 16px;
    text-align: center;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;

    transition: all 0.25s ease-in-out;

    &.button--active {
        color: #1F7A66;
    }

    &:hover {
        color: #1F7A66;
        text-decoration: none;
    }

    &:active {
        color: $color-primary-dark;
    }
}

.dropdown, .dropdown:link, .dropdown:visited {    
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

    transition: all 0.25s ease-in-out;

    &.dropdown--active {
        & img {
            filter: brightness(1);
            opacity: 1;    
        }
    }

    &:hover {
        color: #1F7A66;
        text-decoration: none;
        
        & img {
            filter: brightness(1);
            opacity: 1;    
        }
    }

    &:active {
        color: $color-primary-dark;
    }

    & img {
        margin-top: 2px;
        transition: all 0.25s ease-in-out;
        filter: brightness(0);
        opacity: 0.8;    
    }
}

.modal__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
}

.modal__content {
    overflow-x: hidden !important;
}

.modal__slides {
    position: relative;
}

.modal-container {
    overflow: hidden;
    margin-bottom: 20px;
    transform: translateX(0%);
    transition: all 0.25s ease-in-out;
}

.modal-container--inactive {
    transform: translateX(-100%);
}

.modal-container--active {
    transform: translateX(0%);
}

.modal-container--slide {
    position: absolute;
    top: 0;
    width: 100vw;

    &:not(.modal-container--active) {
        transform: translateX(100%);
    }
}

.modal-container, .popup-container {
    display: flex;
    flex-direction: column;

    .button, .button:link, .button:visited {
        text-align: left;

        &:hover {
            background: rgba(#1F7A66, 0.08);
        }

        &:active {
            background: rgba(#1F7A66, 0.12);
        }
    }
}

.popup-container {
    display: flex;
    flex-direction: column;
}

.main__menu--mobile {
    display: none;
}

.dropdown-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-icon-wrapper--right {
    transform: rotate(-90deg);
}

.dropdown-icon img {
    width: 12px;
    height: 12px;
}

@include media-desktop-md {
    .button, .button:link, .button:visited {
        font-size: 14px;
        padding: 15px 14px;
    }

    .dropdown, .dropdown:link, .dropdown:visited {
        font-size: 14px;
    }
}

@include media-tablet {
    .container {
        padding: 20px 30px;
        height: $tablet-height;
    }

    .container--scrolled {
        padding: 10px 30px;
        height: $tablet-height - 20px;
    }

    .button, .button:link, .button:visited {
        font-size: 16px;
        padding: 15px 16px;
    }

    .dropdown, .dropdown:link, .dropdown:visited {
        font-size: 16px;
    }
}

@include media-mobile {
    .container {
        padding: 20px 16px;
        height: $mobile-height;
    }

    .modal-container {
        margin-right: -15px;
        margin-left: -15px;
    }

    .container--scrolled {
        padding: 15px 16px;
        height: $mobile-height - 10px;
    }

    .modal__header {
        justify-content: start;
        padding: 20px 16px;
        height: $mobile-height;
    }

    .main {
        justify-content: end;
    }

    .main__menu--mobile {
        display: inherit;
    }

    .main__navigation {
        display: none;
    }

    .actions {
        display: none;
    }

    .container-placeholder {
        height: $mobile-height;
    }

    .logo--normal {
        display: none;
    }

    .logo--small {
        display: block;
    }

    .modal__actions {
        display: flex;
        flex-direction: column;
        align-items: normal;
        padding: 0;
        gap: 10px;
    }

    .modal__actions-divider {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 20px;
    }

    .dropdown, .dropdown:link, .dropdown:visited {
        font-weight: 500;

        width: 100%;
        justify-content: space-between;
        align-items: center;

        & img {
            margin-top: 0;
        }
    }

    .dropdown-icon-wrapper {
        width: 25%;
        height: 110%;
        justify-content: flex-end;
    }
}

.sub-menu-title {
    font-size: 16px;
    line-height: 140%;
    min-height: 52px;
    font-weight: 500;
    color: $color-title-active;
    padding: 15px;
    cursor: pointer;
    white-space: nowrap;

    border: 1px solid #1E20211A;
    border-left: 0px;
    border-right: 0px;
    
    display: flex;
    align-items: center;
    gap: 12px;

    .sub-menu-title__icon {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 13px;
        height: 13px;
    }

    & img {
        transition: all 0.25s ease-in-out;
        filter: brightness(0);
        opacity: 0.8;    
    }

    &:hover {
        background: rgba(31, 122, 102, 0.08);
        color: #1F7A66;
        text-decoration: none;
        transition: all 0.25s ease-in-out;

        & img {
            filter: brightness(1);
            opacity: 1;    
        }
    }
}

.sub-menu-links {
    display: flex;
    flex-direction: column;
}