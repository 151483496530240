@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1400px) {
        @content;
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 78px;
    background: #FFFFFF70;
    box-shadow: 0px -8px 41px 0px #C1C1C140;
    position: sticky;
    top: 0;
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.backButton {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: white;
    border: 1px solid #0000001A;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 188px;
    cursor: pointer;

    @include medium-breakpoint {
        left: 60px;
    }

    @include media-mobile {
        left: 16px;
        width: 32px;
        height: 32px;

        &>img {
            width: 19px !important;
            height: 19px !important;
        }
    }
}