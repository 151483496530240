@import '../styles/tools';

.menu-button {
    background: transparent;
    border: none;
    cursor: pointer;
    width: 32px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    & > span {
        display: block;
        width: 24px;
        height: 2px;
        background: #fff;
        transform-origin: left;
        transition: transform 0.185s ease-in-out;
    }

    &.active {
        & > span {

            &:nth-child(1) {
                width: 26px;
                transform: translate(3px, -4px) rotate(45deg);
            }

            &:nth-child(2) {
                display: none;
            }

            &:nth-child(3) {
                width: 26px;
                transform: translate(3px, 2px) rotate(-45deg);
            }
        }
    }
}

.menu-button--inverted {
    & > span {
        background: #7c7b7c;
    }
}